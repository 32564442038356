import type { DefaultValue, FieldMetadata, Submission, SubmissionResult } from '@conform-to/react';
import { useForm, getSelectProps } from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';
import type { input, output, ZodTypeAny } from 'zod';

export { getFormProps, getInputProps, getSelectProps } from '@conform-to/react';
export { parseWithZod as parseSubmission } from '@conform-to/zod';

interface UseConformOptions<Schema extends ZodTypeAny, FormError extends string[]> {
  schema: Schema;
  lastResult: SubmissionResult<FormError> | null | undefined;
  defaultValue?: DefaultValue<input<Schema>>;
}

export function useConform<Schema extends ZodTypeAny, FormError extends string[]>({
  schema,
  ...rest
}: UseConformOptions<Schema, FormError>) {
  const [form, fields] = useForm<input<Schema>, output<Schema>, FormError>({
    ...rest,
    constraint: getZodConstraint(schema),
    shouldValidate: 'onBlur',
    shouldRevalidate: 'onInput',
    onValidate({ formData }) {
      return parseWithZod(formData, { schema }) as Submission<Schema, FormError, output<Schema>>;
    },
  });
  return { form, fields };
}

type ComboboxValue = string | number | readonly string[] | undefined;

interface ComboboxProps<Schema extends ComboboxValue> extends ReturnType<typeof getSelectProps> {
  defaultValue: Schema;
  multiple?: false;
}

/** Returns props for uncontrolled combobox */
export const getComboboxProps = <Schema extends ComboboxValue>(
  metadata: FieldMetadata<Schema, Record<string, unknown>, unknown>,
): ComboboxProps<Schema> => {
  return getSelectProps(metadata) as ComboboxProps<Schema>;
};
